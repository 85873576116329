import { SHOPPER_PORTAL_VERSION } from '../app/app-version';

export const environment = {
  production: false,
  hmr: false,
  apiZenkiUrl: 'https://dev-gateway.zenki.fi/',
  apiGatewayUrl: 'https://dev-gateway.zenki.fi/',
  apiUrl: 'https://dev-shopper-gateway.zenki.fi/',
  websocket: 'https://dev-shopper-gateway.zenki.fi/pbw/shopper/notifier',
  tiendanube: "https://dev-tiendanube-payment.zenki.fi/",
  enableDefaultLanguage: false,
  keycloak: {
    url: 'https://dev-auth.zenki.fi/auth',
    realm: 'shoppers',
    clientId: 'shopper-session-limited',
  },
  shopperPortalVersion: SHOPPER_PORTAL_VERSION,
  modalEnvironment: 'dev',
  datadog: {
    applicationId: '6a422177-124f-4329-9025-728942fe598e',
    clientToken: 'pubc5af0dcbb84417c74be34ef377e75f18',
  },
};
